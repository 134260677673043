import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import BaseTitle from "./components/BaseTitle.vue";
import router from "./router";

function fnResize() {
  const viewWidth = document.documentElement.clientWidth || window.innerWidth;
  const size = 100 / 1920;
  document.documentElement.style.fontSize =
    viewWidth >= 1200 ? `${viewWidth * size}px` : `${1200 * size}px`;
  //   document.documentElement.style.fontSize = "100px";
}
fnResize();

const app = createApp(App);

app.component("BaseTitle", BaseTitle);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
