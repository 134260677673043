import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/chanPin",
    name: "chanPin",
    component: () =>
      import(/* webpackChunkName: "chanPin" */ "../views/chanPin/index.vue"),
  },
  {
    path: "/chengGongAnLi",
    name: "chengGongAnLi",
    component: () =>
      import(
        /* webpackChunkName: "chengGongAnLi" */ "../views/chengGongAnLi/index.vue"
      ),
  },
  {
    path: "/jieJueFA",
    name: "jieJueFA",
    component: () =>
      import(
        /* webpackChunkName: "jieJueFA" */ "../views/jieJueFangAn/index.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/index.vue"),
    children: [
      {
        path: "zxd",
        name: "zxd",
        component: () => import("../views/about/zxd.vue"),
      },
      {
        path: "our",
        name: "our",
        component: () => import("../views/about/our.vue"),
      },
      {
        path: "info",
        name: "info",
        component: () => import("../views/about/info.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
